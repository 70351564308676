const WgeCorporateBlue = '#1B365D'; // Series 1
/*const WgeLightGrey = '#A7A7A7'; // Series 2*/
const WgeLightGrey = '#e6e6e6'; // Series 2
const WgeSectorsRed = '#A30046'; // Series 3
const WgeWildcatBlue = '#0046A3'; // Series 4
const WgeAnalyticsGreen = '#51AE32'; // Series 5
const WgeRigLogixYellow = '#F39200'; // Series 6
const WgeAtlasPurple = '#941E81'; // Series 7
const WgeEnergentBlue = '#009FE3'; // Series 8
const WgeGlrGreen = '#8DB097'; // Series 9
const WgeFarmOutsOrange = '#D63515'; // Series 10
const WgeRigEdgeBlue = '#006996'; // Series 11
const WgeRigOutlookMaroon = '#560025'; // Series 12
const WgeAdditional1 = '#8E87C3';
const WgeAdditional2 = '#286638';
const WgeAdditional3 = '#D159C9';
const WgeAdditional4 = '#074E66';
const WgeAdditional5 = '#FB9792';
const WgeAdditional6 = '#2C2F2B';
const WgeAdditional7 = '#2A1B90';
const WgeAdditional8 = '#049E33';
const WgeAdditional9 = '#B75818';
const WgeAdditional10 = '#2D172B';
const WgeDarkGrey = '#636362'; // ALTERNATE
const WgeCharcoalPlum = '#211E34';

export {
  WgeCorporateBlue,
  WgeLightGrey,
  WgeDarkGrey,
  WgeSectorsRed,
  WgeWildcatBlue,
  WgeAnalyticsGreen,
  WgeRigLogixYellow,
  WgeAtlasPurple,
  WgeEnergentBlue,
  WgeGlrGreen,
  WgeFarmOutsOrange,
  WgeRigEdgeBlue,
  WgeRigOutlookMaroon,
  WgeAdditional1,
  WgeAdditional2,
  WgeAdditional3,
  WgeAdditional4,
  WgeAdditional5,
  WgeAdditional6,
  WgeAdditional7,
  WgeAdditional8,
  WgeAdditional9,
  WgeAdditional10,
  WgeCharcoalPlum
};
